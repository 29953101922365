import { css } from '@emotion/react';
import { mq } from 'src/theme/variables';
import StemImg1 from 'src/assets/svg/step-1.svg';

export const stepsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  ${mq[1]} {
    width: 80%;
  }
`;

export const stepWrapperCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${mq[0]} {
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px auto;
  }
`;

export const stepImageCSS = css`
  position: relative;

  ${mq[0]} {
    flex-basis: 40%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  ${mq[1]} {
    flex-basis: 50%;
  }
`;

export const stepDescriptionCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-start;
`;

export const stepHeadingWrapperCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const stepCopyCSS = css`
  width: 380px;
  font-size: 20px;
`;

export const stepHeadingNumberCSS = css`
  color: #ea4d72;
  font-weight: bold;
  font-size: 130px;
  line-height: 100px;
  margin-right: 24px;
`;

export const stepHeadingCSS = css`
  font-size: 44px;
  font-weight: 900;
  line-height: 54px;
  width: 300px;
`;

export const savingsWrapperCSS = css`
  position: absolute;
  right: 25%;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
`;

export const savingsCSS = css`
  font-weight: bold;
  font-size: 36px;
  line-height: 34px;
`;

export const headingCSS = css`
  font-size: 32px;
  font-weight: bold;

  ${mq[0]} {
    font-size: 40px;
  }
`;

export const descriptionCSS = css`
  font-size: 14px;

  ${mq[0]} {
    text-align: center;
    font-size: 16px;
    margin-bottom: 60px;
  }
`;

export const profileWrapperCSS = css`
  background-image: url(${StemImg1});
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  height: 300px;
  width: 300px;

  ${mq[0]} {
    height: 290px;
  }

  ${mq[1]} {
    height: 370px;
    width: 460px;
  }
`;
export const profileCustomCSS = css`
  top: 7%;
  position: absolute;
  width: 210px;
  left: 35px;
  padding-top: 10;
  padding-bottom: 10;

  ${mq[1]} {
    top: 18%;
    position: absolute;
    width: 410px;
    left: -25px;
  }
`;
