import { css } from '@emotion/react';
import { colors } from 'src/theme/variables';
import { text } from 'src/theme/typography';

export const itemCSS = css`
  padding: 16px 0;
`;

export const buttonCSS = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
`;

export const arrowCSS = css`
  [aria-expanded='true'] & {
    transform: rotate(-180deg);
  }

  height: 30px;
`;

export const panelCSS = css`
  color: ${colors.warmGray};
  margin-top: 12px;
`;

export const titleCSS = css`
  ${text}
`;
