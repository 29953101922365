/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { componentsBackgroundCSS, componentsPaddingCSS, faqCSS } from './AllFunnelExpirationLanding.style';

import { DefaultLocation } from 'src/interfaces/IPage';
import useConfig from 'src/api/config/useConfig';
import useResponsive from 'src/hooks/useResponsive';
import Container from 'src/kit/Container/Container';
import GoogleReviews from 'src/components/Landing/GoogleReviews/GoogleReviews';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';
import { getAge } from 'src/utils/date';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { Answers } from 'src/interfaces/IAnswer';
import WhyTrustMatic from 'src/components/WhyTrustMatic/WhyTrustMatic';
import useQuestions from 'src/api/questions/useQuestions';
import { googleReviewsCSS } from 'src/components/SavingsMainContent/SavingsMainContent.style';
import FAQ from 'src/components/FAQ/FAQ';
import { getServicerNameToDisplay } from 'src/constants/servicers';
import { getAccordionItems } from 'src/components/Savings/Savings.utils';
import HowItWorksExpiration from 'src/components/HowItWorksExpiration/HowItWorksExpiration';

const AllFunnelExpirationLanding: React.FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { isMobile } = useResponsive();

  const { data: config } = useConfig(gid);
  const { data: questions } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const flowStartedReported = useRef(false);
  const servicerName = getServicerNameToDisplay(config?.servicer_key, config?.servicer_name);

  useEffect(() => {
    if (!flowStartedReported.current && config?.servicer_key) {
      analytics.track(SEGMENT.FLOW_STARTED, gid, flow, {
        partner_key: config?.servicer_key
      });
      flowStartedReported.current = true;
    }
  }, [flow, gid, config]);

  useEffect(() => {
    if (answers.person_gid && config?.servicer_key) {
      const address = answers.property_address as AddressAnswer;

      analytics.identify(answers.person_gid, {
        email: analytics.generateTrackEmail(answers.person_gid as string),
        state: address?.state ?? undefined,
        age: answers.person_date_of_birth ? getAge(answers.person_date_of_birth as string) : undefined
      });

      analytics.page(SEGMENT.PAGES.All_FUNNEL, {
        flow_type: flow,
        session_gid: gid,
        partner_key: config?.servicer_key
      });
    }
  }, [answers, flow, gid, config]);

  const onGoogleReviewsClick = () =>
    analytics.track(SEGMENT.GOOGLE_REVIEWS_CLICKED, gid, flow, {
      layout: isMobile ? 'mobile' : 'desktop',
      location: SEGMENT.PAGES_KEY.ALL_FUNNEL
    });

  return (
    <div>
      <HowItWorksExpiration savingsLabel={config?.partner.savings_label} />
      <Container customCSS={componentsPaddingCSS}>
        <GoogleReviews
          isExpirationVariation
          customCSS={{ wrapper: googleReviewsCSS }}
          heading="Why homeowners trust Matic"
          onGoogleReviewsClick={onGoogleReviewsClick}
        />
      </Container>
      <div css={[componentsBackgroundCSS, componentsPaddingCSS]}>
        <WhyTrustMatic isExpirationVariation savings={config?.partner.savings_label ?? null} />
      </div>
      <Container customCSS={componentsPaddingCSS}>
        <FAQ customCSS={faqCSS} page={SEGMENT.PAGES.All_FUNNEL} accordionItems={getAccordionItems(servicerName)} />
      </Container>
    </div>
  );
};

export default AllFunnelExpirationLanding;
