/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import GoogleReviewCard from 'src/components/Landing/GoogleReviewCard/GoogleReviewCard';
import GoogleReviewRating from 'src/components/Landing/GoogleReviewRating/GoogleReviewRating';
import {
  carouselCSS,
  contentCSS,
  desktopViewCSS,
  headerCSS,
  ratingCSS,
  cardCSS,
  disclaimerCSS
} from './GoogleReview.style';
import useResponsive from 'src/hooks/useResponsive';
import GoogleReviewCarousel from 'src/components/Landing/GoogleReviewCarousel/GoogleReviewCarousel';
import { GoogleReviewsProps } from './GoogleReviews.types';

const GOOGLE_REVIEWS = [
  {
    text:
      'Matic took good care of me and answered all my questions. They were able to help me save a lot of money on ' +
      'my mortgage by finding me a lower rate on my home insurance.',
    author: 'Adilson V.'
  },
  {
    text: 'They made it easy to change insurance companies and saved me almost $600 a year auto/home combined.',
    author: 'James D.'
  },
  {
    text:
      'Over the course of a short phone call, they Matic rep both increased my coverage and reduced my premiums ' +
      'by nearly 50%.',
    author: 'Donna R.'
  }
];

const GOOGLE_REVIEWS_EXP = [
  {
    text:
      'I was referred to Matic by my mortgage lender, and I couldn`t be more grateful. ' +
      ' They found a great bundle for my home and auto insurance with amazing coverage at ' +
      'a competitive rate. Everything was very easy! ',
    author: 'Jaime G'
  },
  {
    text:
      'Best customer service! The agent was amazing. He took time to answer all of my ' +
      'questions and make sure I completely understood my new policy. He made the dreaded ' +
      'task of finding new insurance simple, easy and painless!',
    author: 'Bobbi L.'
  },
  {
    text:
      'Good service and a great price! Better coverage than my old insurance company, and I saved ' +
      'approximately $600.',
    author: 'Steve B.'
  }
];

const DISCLAIMER = '* Testimonials are actual Matic customer experiences. Your results may vary.';

const GoogleReviews: React.FC<GoogleReviewsProps> = ({
  onGoogleReviewsClick,
  heading = 'Real People, Really Happy Customers',
  withControls = false,
  customCSS = {},
  isExpirationVariation = false
}) => {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from('.google-review-cardCSS', {
      y: 40,
      opacity: 0,
      stagger: 0.1,
      scrollTrigger: {
        trigger: '#googleReviewCardWrapper',
        start: '150px bottom',
        end: 'bottom top'
      }
    });
  }, []);

  const reviewsCards = isExpirationVariation ? GOOGLE_REVIEWS_EXP : GOOGLE_REVIEWS;

  return (
    <div css={customCSS.wrapper}>
      <h2 css={[headerCSS(isExpirationVariation), customCSS.heading]}>{heading}</h2>
      <div css={contentCSS}>
        <GoogleReviewRating
          customCSS={ratingCSS}
          disclaimer={isMobile || isTablet ? DISCLAIMER : ''}
          onGoogleReviewsClick={onGoogleReviewsClick}
          isExpirationVariation={isExpirationVariation}
        />
        {isMobile ? (
          <GoogleReviewCarousel customCSS={carouselCSS} withControls={withControls} reviews={reviewsCards} />
        ) : (
          <div>
            <div css={desktopViewCSS} id="googleReviewCardWrapper">
              {reviewsCards.map(item => (
                <div className="google-review-cardCSS" css={cardCSS} key={item.author}>
                  <GoogleReviewCard text={item.text} author={item.author} />
                </div>
              ))}
            </div>
            {isDesktop && <div css={disclaimerCSS}>{DISCLAIMER}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleReviews;
