import { SerializedStyles } from '@emotion/react';

export interface GoogleReviewRatingProps {
  customCSS?: SerializedStyles;
  disclaimer?: string;
  isExpirationVariation?: boolean;
  onGoogleReviewsClick?(): void;
  layoutVariant?: GoogleReviewsLayoutVariant;
  isSimplified?: boolean;
}

export enum GoogleReviewsLayoutVariant {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}
