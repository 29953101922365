/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import { WhyTrustMaticProps } from './WhyTrustMatic.props';
import {
  defaultContainerCSS,
  headingCSS,
  factHeadingCSS,
  factWrapperCSS,
  factsWrapperCSS,
  descriptionCSS
} from './WhyTrustMatic.style';

import { getWhyMaticFacts, getWhyMaticFactsExpiration } from './WhyTrustMatic.utils';

const WhyTrustMatic: React.FunctionComponent<WhyTrustMaticProps> = ({
  savings,
  isNewRez,
  customCSS,
  isExpirationVariation = false
}) => {
  const factsToRender = isExpirationVariation
    ? getWhyMaticFactsExpiration(savings)
    : getWhyMaticFacts(savings, isNewRez);
  return (
    <div css={[defaultContainerCSS, customCSS]}>
      <div>
        <h2 css={headingCSS(isExpirationVariation)}>{isExpirationVariation ? 'Why Matic ?' : 'Why Trust Matic'}</h2>
        <div css={factsWrapperCSS}>
          {factsToRender.map(({ heading, description }, index) => (
            <div css={factWrapperCSS(isExpirationVariation)} key={heading}>
              <div css={factHeadingCSS(isExpirationVariation)}>{heading}</div>
              <div css={descriptionCSS}>
                {description.map((subst, j) => (
                  <Fragment key={`${index}-${j}`}>
                    {j > 0 && <br />}
                    {subst}
                  </Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyTrustMatic;
