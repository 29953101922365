/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Footer from 'src/components/Footer/Footer';
import Page from 'src/components/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import SEGMENT from 'src/constants/segment';
import { visuallyHidden } from 'src/theme/helpers';
import AllFunnelLanding from 'src/components/AllFunnelLanding/AllFunnelLanding';
import useTrackFullStorySession from 'src/hooks/useTrackFullStorySession';
import useTrackAdBlocker from 'src/hooks/useTrackAdBlocker';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import useInitFeatureToggles from 'src/hooks/useInitFeatureToggles/useInitFeatureToggles';
import AllFunnelExpirationLanding from 'src/components/AllFunnelExpirationLanding/AllFunnelExpirationLanding';

const PAGE_TITLE = 'Matic Insurance Instant Quotes Result';

const AllFunnelLayout: React.FC = () => {
  const { gid } = useParams();
  const [isExpirationLanding, setIsExpirationLanding] = useState(false);
  useTrackFullStorySession(gid);
  useTrackAdBlocker(gid);
  const features = useInitFeatureToggles();

  useEffect(() => {
    document.title = PAGE_TITLE;

    const allFunnelExpirationExperiment = features?.[FT.ALL_FUNNEL_EXPIRATION];

    allFunnelExpirationExperiment?.isEnabled &&
      setIsExpirationLanding(!allFunnelExpirationExperiment?.isControlVariation);
  }, [features]);

  return (
    <Page>
      {/*TODO sync for refactor SEGMENT.PAGES_KEY.LANDING because it`s not landing page*/}
      <PageHeader page={SEGMENT.PAGES_KEY.LANDING} />
      <main>
        <h1 css={visuallyHidden}>{PAGE_TITLE}</h1>
        {isExpirationLanding ? <AllFunnelExpirationLanding /> : <AllFunnelLanding />}
      </main>
      <Footer withConfetti page={SEGMENT.PAGES_KEY.ALL_FUNNEL} />
    </Page>
  );
};

export default AllFunnelLayout;
