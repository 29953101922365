import React, { Fragment } from 'react';
import { Step } from 'src/components/HowItWorksSteps/HowItWorksSteps.types';
import compareIcon from 'src/assets/svg/how-savings-works-compare.svg';
import savingsIcon from 'src/assets/svg/how-savings-works-safe.svg';
import relaxIcon from 'src/assets/svg/how-it-works-cup.svg';
import SERVICERS from 'src/constants/servicers';
import { AccordionItem } from 'src/shared/components/Accordion/Accordion.types';

export const getSavingsBenefitsSteps = (partner: string | React.ReactElement): Step[] => [
  {
    img: compareIcon,
    imgAlt: 'compare-policies-icon',
    heading: 'Matic Compares Policies ',
    description: (
      <Fragment>
        We work closely with {partner} to get you the most accurate quote (based on your current coverage).
      </Fragment>
    )
  },

  {
    img: savingsIcon,
    imgAlt: 'compare-icon',
    heading: 'You Review your Savings',
    description:
      'You can set a time to chat with a Matic Advisor. Not bound to one carrier, ' +
      'they can help you get the best price from our network of over 40 A-rated carriers.'
  },
  {
    img: relaxIcon,
    imgAlt: 'relax-icon',
    heading: 'We Take Care of the Rest',
    description: (
      <Fragment>
        We&rsquo;ll work with {partner} to make sure your policy is all set. That&rsquo;s less paperwork for you.
      </Fragment>
    )
  }
];

export const getCarriersListHeader = (
  partnerKey: string,
  partnerName: string | React.ReactElement
): string | React.ReactElement => {
  return [SERVICERS.MR_COOPER, SERVICERS.MR_COOPER_NRZ].includes(partnerKey) ? (
    <Fragment>
      Mr. Cooper<sup>®</sup> and Matic Team Up to Find You Better Rates on Home Insurance
    </Fragment>
  ) : (
    <Fragment>{partnerName} and Matic Team Up to Find You Better Rates on Home Insurance</Fragment>
  );
};

export const getCarriersListSubheader = (
  isPHHNewRez: boolean,
  partnerName: string | React.ReactElement
): string | React.ReactElement => {
  return isPHHNewRez ? (
    'Newrez works with Matic to help customers like you shop for home insurance. Our free comparison ' +
      ' tool quickly runs your current policy against 40+ A-rated carriers. Seconds later, you have ' +
      'your top quotes, with a clear breakdown of coverages. It’s a beautiful thing.'
  ) : (
    <Fragment>
      {partnerName} works with Matic to make it easy to shop and compare home insurance. In just one click, Matic shops
      your current policy against their network of A-rated insurance carriers to find the best rates available. No
      questions to answer. No forms to complete.
    </Fragment>
  );
};

export const getAccordionItems = (servicerName: string | React.ReactElement): AccordionItem[] => [
  {
    key: '1',
    title: 'Where is this offer coming from?',
    description: (
      <span>
        This offer is coming from Matic, an insurance marketplace backed by a fully licensed agency. {servicerName}
        partners with Matic to help borrowers access competitive home and auto insurance rates and lower their monthly
        expenses.
      </span>
    )
  },
  {
    key: '2',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        Insurance carriers pay Matic a commission when you purchase a policy. Matic advisors are not paid based on the
        policy premium, so we’re focused on finding the right option for you.
      </span>
    )
  },
  {
    key: '3',
    title: 'Who is Matic?',
    description: (
      <span>
        Matic is an insurance marketplace backed by a fully licensed agency. We work with 40+ A-rated home and auto
        carriers to match you with the policy that best suits your needs. Matic was selected by {servicerName} to help
        borrowers like you lower their monthly expenses.
      </span>
    )
  },
  {
    key: '4',
    title: 'What does Matic charge?',
    description: (
      <span>
        Nothing! It’s free to shop for insurance with Matic. Plus, there are no extra fees when you purchase a policy
        through Matic. Only pay the cost of your premium.
      </span>
    )
  },
  {
    key: '5',
    title: 'Can I bundle a car?',
    description: <span>Yes, you can bundle your home and auto insurance and save up to 20%.**</span>
  }
];
