/** @jsxImportSource @emotion/react */
import {
  stepsWrapperCSS,
  stepCopyCSS,
  stepDescriptionCSS,
  stepHeadingWrapperCSS,
  stepImageCSS,
  stepWrapperCSS,
  stepHeadingNumberCSS,
  stepHeadingCSS,
  savingsWrapperCSS,
  savingsCSS,
  profileWrapperCSS,
  profileCustomCSS,
  headingCSS,
  descriptionCSS
} from './HowItWorksExpiration.style';
import StepFAQImg from 'src/assets/svg/step-faq.svg?react';
import StepGetQuoteImg from 'src/assets/svg/get-quote.svg?react';
import QuotesUserProfile from 'src/components/Quotes/StandardHouseQuotes/QuotesUserProfile/QuotesUserProfile';
import { AFAnswers } from 'src/components/AllFunnelOfferForm/AllFunnelOfferForm.types';
import useQuestions from 'src/api/questions/useQuestions';
import { DefaultLocation } from 'src/interfaces/IPage';
import { useParams } from 'react-router-dom';
import { Answers } from 'src/interfaces/IAnswer';
import { useMemo } from 'react';

interface HowItWorksExpirationProps {
  savingsLabel?: number;
}

const HowItWorksExpiration: React.FC<HowItWorksExpirationProps> = ({ savingsLabel }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: questions } = useQuestions(gid);
  const answers = useMemo(() => questions?.answers || ({} as Answers), [questions]);
  const { person_first_name, person_last_name, current_policy } = answers as AFAnswers;

  return (
    <div css={stepsWrapperCSS}>
      <div css={headingCSS}>How Matic works</div>
      <div css={descriptionCSS}>
        Matic compares your current policy with 40+ top carriers to find competitive rates, saving customers days of
        work and ${savingsLabel} on average.*
      </div>
      <div css={stepWrapperCSS}>
        <div css={stepImageCSS}>
          <div css={profileWrapperCSS}>
            <QuotesUserProfile
              homeDetails={{}}
              lastName={person_last_name}
              firstName={person_first_name}
              carrierName={current_policy?.carrier_name}
              currentPolicy={current_policy}
              shouldRenderStepsExtended
              customCSS={profileCustomCSS}
            />
          </div>
        </div>
        <div css={stepDescriptionCSS}>
          <div css={stepHeadingWrapperCSS}>
            <div css={stepHeadingNumberCSS}>1</div>
            <div css={stepHeadingCSS}>Share your property info </div>
          </div>
          <div css={stepCopyCSS}>
            Tell us about your home through an easy online form. We’ll use this info to customize your coverage, find
            discounts, and deliver quotes that accurately reflect your home’s features (like a security system or the
            age of your roof).
          </div>
        </div>
      </div>

      <div css={stepWrapperCSS}>
        <div css={stepDescriptionCSS}>
          <div css={stepHeadingWrapperCSS}>
            <div css={stepHeadingNumberCSS}>2</div>
            <div css={stepHeadingCSS}>Get your quote</div>
          </div>
          <div css={stepCopyCSS}>
            Matic’s platform doesn’t favor specific carriers and returns your best-fit quote from a network of 40+
            insurers. Review the quote for details on coverage and premiums to check that the policy meets your needs.
          </div>
        </div>

        <div css={stepImageCSS}>
          <div css={savingsWrapperCSS}>
            <div>Save</div>
            <div css={savingsCSS}>${savingsLabel}</div>
            <div>Per Year</div>
          </div>
          <StepGetQuoteImg />
        </div>
      </div>

      <div css={stepWrapperCSS}>
        <div css={stepImageCSS}>
          <StepFAQImg />
        </div>
        <div css={stepDescriptionCSS}>
          <div css={stepHeadingWrapperCSS}>
            <div css={stepHeadingNumberCSS}>3</div>
            <div css={stepHeadingCSS}>Buy your policy</div>
          </div>
          <div css={stepCopyCSS}>
            Buy online with a digital-first carrier, or talk to a licensed advisor to finalize your policy. Once you’re
            a customer, Matic is there to help you navigate any policy issues or changes.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksExpiration;
