import { css, SerializedStyles } from '@emotion/react';
import { mq, sizes } from 'src/theme/variables';
import { text, text20 } from 'src/theme/typography';

export const containerCSS = css`
  padding: 20px ${sizes.paddingBodyHorizontalMobileDense}px;

  ${mq[0]} {
    padding: 20px;
    box-sizing: border-box;
  }
`;

export const headingCSS = css`
  ${text20};
  font-weight: 700;
`;

export const userCSS = (shouldRenderCallMeNow?: boolean): SerializedStyles => css`
  ${text};
  font-weight: 700;
  margin-top: 4px;

  ${shouldRenderCallMeNow && 'text-align: right;'}

  ${mq[0]} {
    margin-top: 12px;
    text-align: unset;
  }
`;

export const detailsWrapperCSS = (shouldRenderCallMeNow?: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: column;

  ${mq[0]} {
    ${shouldRenderCallMeNow &&
    `
      flex-direction: row;
      justify-content: space-between;
      `}
  }
`;

export const currentPolicyWrapperCSS = (shouldRenderCallMeNow?: boolean): SerializedStyles => css`
  margin-top: 20px;
  box-sizing: border-box;

  ${mq[0]} {
    margin-top: 12px;
    padding-left: 10px;
    flex-basis: ${shouldRenderCallMeNow ? '48%' : '50%'};
    flex-shrink: 0;

    ${shouldRenderCallMeNow &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
  }

  ${mq[1]} {
    padding-left: 0;
    margin-top: 4px;
  }
`;

export const carrierCSS = css`
  ${text};
  font-weight: 700;
`;

export const detailsItemCSS = css`
  width: 100%;
  margin-right: 28px;
  margin-top: 12px;
  &:last-child {
    margin-right: 0;
  }

  ${mq[0]} {
    margin-right: 0;
    margin-top: 12px;
  }
`;

export const homeDetailsWrapperCSS = (shouldRenderCallMeNow?: boolean): SerializedStyles => css`
  margin-top: 20px;
  box-sizing: border-box;

  ${mq[0]} {
    margin-top: 12px;
    padding-right: 10px;
    flex-basis: ${shouldRenderCallMeNow ? '48%' : '50%'};
    flex-shrink: 0;

    ${shouldRenderCallMeNow &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
  }

  ${mq[1]} {
    padding-right: 0;
    margin-top: 20px;

    ${shouldRenderCallMeNow &&
    css`
      margin-top: 4px;
    `}
  }
`;

export const addressCSS = css`
  font-weight: 700;
`;

export const spaceCSS = (hasContent?: boolean): SerializedStyles => css`
  display: ${hasContent ? 'block' : 'none'};
  margin-top: 20px;

  ${mq[0]} {
    margin-top: 12px;
  }

  ${mq[1]} {
    margin-top: 20px;
  }
`;

export const profileHeadingCSS = css`
  display: flex;
  ${text20};
  font-weight: 700;
`;

export const policyExtendedWrapperCSS = css`
  display: grid;
  grid-template-columns: repeat(1, 200px);
  grid-column-gap: 18px;
  padding-top: 10px;

  ${mq[0]} {
    grid-template-columns: repeat(1, 47%);
  }

  ${mq[1]} {
    grid-template-columns: repeat(2, 47%);
  }
`;

export const heartCSS = css`
  margin-right: 12px !important;
  width: 30px;

  ${mq[1]} {
    width: 60px;
  }
`;

export const lineCSS = css`
  margin-bottom: 12px;
  width: 100%;
`;

export const smallHeadingCSS = css`
  ${text};
  margin-top: 2px;
`;

export const bracketsCSS = css`
  position: absolute;
  left: -20px;
  top: -30px;
`;

export const tooltipCSS = css`
  position: absolute;
  top: -52px;
  right: -34px;
  width: 84px;

  ${mq[1]} {
    width: 157px;
    top: -105px;
    right: 0px;
  }
`;

export const headerWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
