import { css } from '@emotion/react';
import { mq, colors } from 'src/theme/variables';
import SIRV from 'src/constants/sirv';
import { text24, text40 } from 'src/theme/typography';

export const heroCSS = css`
  background-position-y: -200px;
  background-image: url(${SIRV.AF_MOBILE_BANNER});
  background-position: 99% 98%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  text-align: center;

  ${mq[0]} {
    background-image: url(${SIRV.AF_DESKTOP_BANNER});
  }
`;

export const componentsPaddingCSS = css`
  padding: 32px 12px;

  ${mq[0]} {
    padding: 56px 20px;
  }

  ${mq[1]} {
    padding: 56px 12px;
  }
`;

export const componentsBackgroundCSS = css`
  background-color: ${colors.grayFive};
`;

export const userProfileCSS = css`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const faqCSS = css`
  overflow-x: hidden;
`;

export const userProfileWrapperCSS = css`
  min-width: 300px;
`;

export const profileHeadingCss = css`
  display: none;
`;

export const heroHeadingCustomCSS = css`
  ${text24};

  ${mq[0]} {
    ${text40};
    padding: 0;
  }
`;

export const heroFormWrapperCustomCSS = css`
  padding-top: 0;

  ${mq[0]} {
    padding-top: 48px;
  }
`;
